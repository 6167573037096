import Cookies from 'js-cookie';
import React, { useState, useEffect } from 'react';

import { Card, Link, Typography, IconButton, CardContent } from '@mui/material';

import { paths } from 'src/routes/paths';
import Iconify from '../iconify';

const CookieBanner: React.FC = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const cookieBannerClosed = Cookies.get('TispiCookieBannerClosed');
    if (!cookieBannerClosed) {
      setOpen(true);
    }
  }, []);

  const handleClose = () => {
    Cookies.set('TispiCookieBannerClosed', 'true', { expires: 365 });
    setOpen(false);
  };

  if (!open) return null;

  return (
    <Card
      sx={{
        position: 'fixed',
        bottom: 16,
        left: '50%',
        transform: 'translateX(-50%)',
        maxWidth: 600,
        width: '90%',
        boxShadow: 3,
        zIndex: 1300,
      }}
    >
      <CardContent>
        <Typography variant="body2">
          Per far funzionare Trispi, registriamo i dati degli utenti. Utilizzando Trispi, accetti la
          nostra{' '}
          <Link target="_blank" href={paths.privacyPolicy}>
            Privacy Policy
          </Link>
          , inclusa la{' '}
          <Link target="_blank" href={paths.cookiesPolicy}>
            Cookie Policy
          </Link>
          .
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', top: 8, right: 8 }}
        >
          <Iconify icon="tabler:x" />
        </IconButton>
      </CardContent>
    </Card>
  );
};

export default CookieBanner;

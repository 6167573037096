import { forwardRef } from 'react';
// @mui
import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';
// import { useSettingsContext } from 'src/components/settings/context';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    // const settings = useSettingsContext();

    // const PRIMARY_LIGHT = theme.palette.primary.light;

    // const PRIMARY_MAIN = settings.themeMode === 'dark' ? '#ffffff' : '#000000';

    // const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 50,
          height: 50,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <svg
          id="Livello_1"
          data-name="Livello 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1080 1080"
        >
          <defs>
            <clipPath id="clippath">
              <rect
                x="155.94"
                y="360.23"
                width="768.12"
                height="379.53"
                style={{
                  fill: 'none',
                  strokeWidth: '0px',
                }}
              />
            </clipPath>
          </defs>
          <rect
            width="1080"
            height="1080"
            rx="214.52"
            ry="214.52"
            style={{
              fill: '#8232dc',
              strokeWidth: '0px',
            }}
          />
          <g
            style={{
              clipPath: 'url(#clippath)',
            }}
          >
            <path
              d="M281.66,646.44c5.73-1.22,10.92-2.99,15.55-5.31,4.63-2.32,8.48-5.37,11.53-9.15,3.05-3.78,4.58-8.23,4.58-13.36,0-6.83-1.41-12.44-4.21-16.84-2.81-4.39-7.38-6.59-13.72-6.59-3.66,0-7.32.31-10.98.91-3.66.61-7.2.92-10.61.92-5.62,0-10.01-1.4-13.17-4.21-3.17-2.8-5.49-6.16-6.95-10.06-1.46-3.9-2.32-7.99-2.56-12.26-.25-4.27-.37-7.99-.37-11.16,0-4.39.18-8.72.55-12.99.37-4.27.79-8.47,1.28-12.63.73-8.54,1.4-17.02,2.01-25.44.61-8.42,1.65-17.62,3.11-27.63,5.36-.49,11.1-1.4,17.2-2.75,6.1-1.34,11.83-3.23,17.2-5.67,5.36-2.44,9.82-5.36,13.36-8.78,3.53-3.41,5.31-7.32,5.31-11.71,0-3.9-1.22-7.26-3.66-10.06-2.44-2.8-5.55-5.06-9.33-6.77-3.79-1.7-7.93-2.93-12.44-3.66-4.52-.73-8.85-1.1-12.99-1.1-1.22,0-2.56.06-4.03.18-1.46.13-4.27.43-8.42.92,1.95-7.56,3.29-13.91,4.03-19.03.73-5.12,1.1-10.12,1.1-15.01,0-4.15-.49-8.42-1.46-12.81-.98-4.39-2.44-8.35-4.39-11.89-1.96-3.53-4.45-6.46-7.5-8.78-3.05-2.32-6.53-3.48-10.43-3.48-6.83,0-12.44,2.44-16.84,7.32-4.39,4.88-7.87,10.92-10.43,18.12-2.56,7.2-4.33,14.95-5.31,23.24-.98,8.3-1.46,15.86-1.46,22.69-5.37.49-11.04,1.46-17.02,2.93-5.98,1.46-11.53,3.42-16.65,5.86-5.12,2.44-9.33,5.37-12.63,8.78-3.29,3.42-4.94,7.32-4.94,11.71,0,7.08,2.87,12.32,8.6,15.74,5.73,3.42,12.5,5.12,20.31,5.12,1.22,0,2.8-.06,4.76-.18,1.95-.12,4.63-.18,8.05-.18-1.46,13.18-2.56,25.56-3.29,37.15-.73,11.59-1.1,23.12-1.1,34.59,0,14.4.49,27.45,1.46,39.16.97,11.71,3.84,21.72,8.6,30.01,4.76,8.3,11.95,14.7,21.59,19.21,9.64,4.51,23.12,6.77,40.44,6.77,5.12,0,10.55-.61,16.29-1.83"
              style={{
                fill: '#ff9100',
                strokeWidth: '0px',
              }}
            />
            <path
              d="M466.3,491.62c5.12-4.15,7.44-10.12,6.95-17.93-.25-6.59-1.89-12.32-4.94-17.2-3.05-4.88-6.95-8.84-11.71-11.89-4.76-3.05-10.01-5.24-15.74-6.59-5.74-1.34-11.53-1.89-17.38-1.65-11.71.49-21.23,3.72-28.55,9.7-7.32,5.98-13.91,13.73-19.76,23.24-.25-3.66-.86-7.69-1.83-12.08-.98-4.39-2.38-8.54-4.21-12.44-1.83-3.9-4.21-7.19-7.14-9.88-2.93-2.68-6.47-4.02-10.61-4.02-7.57,0-13.48,1.96-17.75,5.85-4.27,3.91-7.5,8.73-9.7,14.46-2.2,5.74-3.54,11.9-4.03,18.48-.49,6.59-.73,12.57-.73,17.93,0,8.54.3,17.14.92,25.8.6,8.66,1.03,17.26,1.28,25.8,0,2.69-.06,6.22-.18,10.61-.13,4.39-.13,9.21,0,14.46.12,5.25.37,10.8.73,16.65.37,5.85.91,11.71,1.65,17.57.73,5.62,1.77,11.11,3.11,16.47,1.34,5.37,3.05,10.06,5.12,14.09,2.07,4.03,4.63,7.26,7.69,9.7,3.05,2.44,6.65,3.66,10.8,3.66,5.36,0,9.76-1.59,13.17-4.76,3.41-3.17,6.16-7.07,8.24-11.71,2.07-4.63,3.6-9.57,4.58-14.82.97-5.24,1.58-9.82,1.83-13.72.49-3.66.73-8.66.73-15s.12-13.05.37-20.13c0-6.83.18-13.23.55-19.21.37-5.98.91-10.43,1.65-13.36,1.22-4.39,2.93-8.84,5.12-13.36,2.2-4.51,4.82-8.6,7.87-12.26,3.05-3.66,6.46-6.71,10.25-9.15,3.78-2.44,8.11-3.78,12.99-4.02,2.68-.24,5.12.06,7.32.91,2.19.86,4.39,1.77,6.59,2.75,2.44.98,4.88,1.89,7.32,2.75,2.43.86,5.36,1.16,8.78.91,7.32-.24,13.54-2.44,18.66-6.59"
              style={{
                fill: '#ff9100',
                strokeWidth: '0px',
              }}
            />
            <path
              d="M533.93,368.29c-3.91-2.2-8.18-3.29-12.81-3.29-3.17,0-6.47.49-9.88,1.46-3.42.98-6.47,2.38-9.15,4.21-2.69,1.83-4.94,4.15-6.77,6.95-1.83,2.81-2.74,6.04-2.74,9.7,0,5.12,1.83,8.91,5.49,11.34,3.66,2.44,7.93,3.66,12.81,3.66,2.93,0,6.1-.55,9.52-1.65,3.41-1.1,6.59-2.68,9.51-4.76,2.93-2.07,5.31-4.45,7.14-7.14,1.83-2.68,2.75-5.73,2.75-9.15,0-5.36-1.96-9.15-5.86-11.35"
              style={{
                fill: '#ff9100',
                strokeWidth: '0px',
              }}
            />
            <path
              d="M542.35,484.85c-.25-8.66-1.22-17.39-2.93-26.17-1.71-8.78-4.27-16.47-7.69-23.06-3.42-6.59-8.42-9.88-15-9.88s-11.59,2.2-15.74,6.59c-4.15,4.39-7.5,10.13-10.07,17.2-2.56,7.08-4.52,14.88-5.86,23.42-1.34,8.54-2.26,16.96-2.75,25.25-.49,8.3-.73,16.05-.73,23.24v30.19c0,6.1.24,12.75.73,19.95.49,7.2,1.22,14.64,2.2,22.32.97,7.69,2.5,14.64,4.58,20.86,2.07,6.22,4.69,11.29,7.87,15.19,3.17,3.91,7.07,5.73,11.71,5.49,6.1-.25,11.16-2.5,15.19-6.77,4.03-4.27,7.26-9.82,9.7-16.65,2.44-6.83,4.21-14.4,5.31-22.69,1.1-8.29,1.89-16.47,2.38-24.52.49-8.05.73-15.55.73-22.51s.12-12.5.37-16.65c.24-3.41.37-7.01.37-10.8v-10.8c0-4.15-.13-10.55-.37-19.21"
              style={{
                fill: '#ff9100',
                strokeWidth: '0px',
              }}
            />
            <path
              d="M676.03,422.27c-1.83-3.05-4.21-5.49-7.14-7.32-2.93-1.83-6.28-3.11-10.07-3.84-3.79-.73-7.63-1.1-11.53-1.1-10.25,0-20.01,1.89-29.28,5.67-9.28,3.79-17.51,8.97-24.7,15.55-7.2,6.59-12.94,14.34-17.2,23.24-4.27,8.91-6.41,18.61-6.41,29.1,0,8.54,2.01,16.35,6.04,23.42,4.03,7.08,8.48,13.73,13.36,19.95,4.88,6.22,9.33,12.08,13.36,17.57,4.03,5.49,6.04,10.8,6.04,15.92s-1.34,9.52-4.02,12.44c-2.69,2.93-6.1,5.31-10.25,7.14-4.15,1.83-8.6,3.36-13.36,4.57-4.76,1.23-9.21,2.93-13.36,5.12-4.15,2.2-7.56,5.19-10.25,8.97-2.68,3.78-4.03,9.09-4.03,15.92,0,4.88,1.1,8.85,3.29,11.9,2.2,3.05,4.94,5.43,8.23,7.14,3.29,1.7,7.01,2.86,11.16,3.48,4.15.6,8.29.91,12.44.91,10,0,20.13-2.32,30.38-6.95,10.25-4.63,19.45-10.8,27.63-18.48,8.17-7.69,14.88-16.47,20.13-26.35,5.24-9.88,7.87-19.95,7.87-30.19,0-7.07-1.04-13.05-3.11-17.93-2.08-4.88-4.64-9.02-7.68-12.44-3.05-3.41-6.41-6.46-10.07-9.15-3.66-2.68-7.02-5.31-10.06-7.87-3.05-2.56-5.62-5.43-7.69-8.6-2.08-3.17-3.11-6.95-3.11-11.34,0-8.29,2.38-14.51,7.14-18.67,4.76-4.15,10.07-7.74,15.92-10.8,5.86-3.05,11.16-6.28,15.92-9.7,4.76-3.41,7.14-8.66,7.14-15.74,0-4.63-.92-8.48-2.75-11.53"
              style={{
                fill: '#ff9100',
                strokeWidth: '0px',
              }}
            />
            <path
              d="M852.4,526.03c0-9.02-.86-19.09-2.56-30.19-1.71-11.1-4.7-21.53-8.97-31.29-4.27-9.76-10.13-17.93-17.57-24.52-7.45-6.59-17.02-9.88-28.73-9.88s-21.78,2.2-31.66,6.59c-9.88,4.39-18.61,10.86-26.17,19.4-.73-2.68-1.71-5.61-2.93-8.78-1.22-3.17-2.74-6.16-4.58-8.97-1.83-2.8-4.03-5.12-6.59-6.95-2.56-1.83-5.43-2.75-8.6-2.75-4.88,0-9.03,2.01-12.44,6.04-3.42,4.03-6.22,9.28-8.42,15.74-2.2,6.47-3.84,13.6-4.94,21.41-1.1,7.81-1.89,15.37-2.38,22.69-.49,7.32-.73,14.09-.73,20.31v13.72c0,16.35.12,32.64.37,48.86.24,16.23.37,32.52.37,48.86v15.01c0,6.83.12,14.39.37,22.69.24,8.29.85,16.96,1.83,25.99.97,9.02,2.38,17.2,4.21,24.52,1.83,7.32,4.33,13.36,7.5,18.12,3.17,4.76,7.07,7.14,11.71,7.14,8.05,0,14.21-2.51,18.48-7.5,4.26-5,7.32-11.04,9.15-18.12,1.83-7.08,2.8-14.52,2.93-22.33.12-7.81.3-14.52.55-20.13l3.29-52.7c1.46,3.42,3.53,6.16,6.22,8.24,2.68,2.08,5.67,3.72,8.97,4.94,3.29,1.22,6.77,2.08,10.43,2.56,3.66.49,7.19.73,10.61.73,13.41,0,24.58-3.66,33.49-10.98,8.9-7.32,16.1-16.47,21.59-27.45,5.49-10.98,9.39-22.87,11.71-35.68,2.32-12.81,3.48-24.58,3.48-35.32M800.07,523.1c0,3.66-.31,8.36-.92,14.09-.61,5.74-1.83,11.29-3.66,16.65-1.83,5.37-4.33,10.01-7.5,13.91-3.18,3.91-7.32,5.86-12.44,5.86-2.2,0-4.82-.37-7.87-1.1-3.05-.73-6.04-1.83-8.97-3.29-2.93-1.46-5.62-3.11-8.05-4.94-2.44-1.83-4.03-3.84-4.76-6.04-.73-1.46-1.28-3.53-1.65-6.22-.37-2.68-.67-5.49-.91-8.42-.25-2.93-.43-5.79-.55-8.6-.13-2.8-.18-5.06-.18-6.77,0-2.2.12-5.49.37-9.88.24-4.39.97-7.56,2.2-9.52,3.17-4.88,7.99-9.21,14.46-12.99,6.46-3.78,12.63-5.67,18.48-5.67,4.39,0,8.05,1.04,10.98,3.11,2.93,2.08,5.18,4.76,6.77,8.05,1.58,3.29,2.68,6.83,3.29,10.61.61,3.79.92,7.5.92,11.16"
              style={{
                fill: '#ff9100',
                strokeWidth: '0px',
              }}
            />
            <path
              d="M915.27,368.29c-3.91-2.2-8.18-3.29-12.81-3.29-3.17,0-6.47.49-9.88,1.46-3.42.98-6.47,2.38-9.15,4.21-2.69,1.83-4.94,4.15-6.77,6.95-1.83,2.81-2.74,6.04-2.74,9.7,0,5.12,1.83,8.91,5.49,11.34,3.66,2.44,7.93,3.66,12.81,3.66,2.93,0,6.1-.55,9.52-1.65,3.41-1.1,6.59-2.68,9.52-4.76,2.93-2.07,5.31-4.45,7.14-7.14,1.83-2.68,2.75-5.73,2.75-9.15,0-5.36-1.96-9.15-5.86-11.35"
              style={{
                fill: '#ff9100',
                strokeWidth: '0px',
              }}
            />
            <path
              d="M923.69,484.85c-.25-8.66-1.22-17.39-2.93-26.17-1.71-8.78-4.27-16.47-7.69-23.06-3.42-6.59-8.42-9.88-15-9.88s-11.59,2.2-15.74,6.59c-4.15,4.39-7.5,10.13-10.07,17.2-2.56,7.08-4.52,14.88-5.86,23.42-1.34,8.54-2.26,16.96-2.75,25.25-.49,8.3-.73,16.05-.73,23.24v30.19c0,6.1.24,12.75.73,19.95.49,7.2,1.22,14.64,2.2,22.32.97,7.69,2.5,14.64,4.57,20.86,2.07,6.22,4.69,11.29,7.87,15.19,3.17,3.91,7.07,5.73,11.71,5.49,6.1-.25,11.16-2.5,15.19-6.77,4.03-4.27,7.26-9.82,9.7-16.65,2.44-6.83,4.21-14.4,5.31-22.69,1.1-8.29,1.89-16.47,2.38-24.52.49-8.05.73-15.55.73-22.51s.12-12.5.37-16.65c.24-3.41.37-7.01.37-10.8v-10.8c0-4.15-.13-10.55-.37-19.21"
              style={{
                fill: '#ff9100',
                strokeWidth: '0px',
              }}
            />
          </g>
        </svg>
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;

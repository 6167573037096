import { PATH_LANDING } from 'src/config-global';

export const paths = {
  login: '/login',
  verify: '/verify',
  register: {
    root: '/register',
    associated: (associatedId: string) => `/register/associated/${associatedId}`,
  },
  newPassword: '/new-password',
  forgotPassword: '/forgot-password',
  termsOfService: `${PATH_LANDING}/terms-of-service`,
  privacyPolicy: `${PATH_LANDING}/privacy-policy`,
  cookiesPolicy: `${PATH_LANDING}/cookies`,
};

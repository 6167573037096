import { Navigate, useRoutes } from 'react-router-dom';

import { lazy } from 'react';
import { authRoutes } from './auth';

// ----------------------------------------------------------------------
const NotFoundPage = lazy(() => import('src/pages/error/404'));

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/login" replace />,
    },

    // Auth routes
    ...authRoutes,

    // No match
    { path: '*', element: <NotFoundPage /> },
  ]);
}
